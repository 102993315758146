export default defineAppConfig({
    ui: {
        icons: {
            dynamic: true
        },
        strategy: 'override',
        tooltip: {
            shortcuts: 'md:inline-flex flex-shrink-0 gap-0.5',
        }
    }
})