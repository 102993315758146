import { defineStore } from 'pinia';
import { useCookie, useRouter } from 'nuxt/app';

export const useUserStore = defineStore('UserStore', {
    state: () => ({
        id : null,
        email: '',
        roles: [],
        projects: [],
        lastname: '',
        firstname: '',
        accessToken: null,
        currentProject: {
            id: null,
            title: ''
        },
    }),
    getters: {
        isAuthenticated: state => Boolean(state.accessToken),
        cookie: state => useCookie('userState'),
    },
    actions: {
        async login(data: Object) {
            const { $api } = useNuxtApp();
            const res = await $api.post('/v1/auth/login', data);

            if(res) {
                this.setUser(res);

                return true;
            }
        },
        async register(data: Object) {
            const { $api } = useNuxtApp();
            const res = await $api.post('/v1/auth/register', data);

            if(res) {
                this.setUser(res);

                return true;
            }
        },
        logout(redirect: string = '/') {
            const router = useRouter();
            const manager = useManagerStore();

            this.resetState();
            manager.reset();

            const userStateCookie = this.cookie;
            userStateCookie.value  = JSON.parse(JSON.stringify(this.$state));

            router.push(redirect);
        },
        setUser(data: Object) {
            // @ts-ignore
            this.id = data?.id || null;
            // @ts-ignore
            this.email = data?.email || '';
            // @ts-ignore
            this.firstname = data?.firstname || '';
            // @ts-ignore
            this.lastname = data?.lastname || '';
            // @ts-ignore
            this.accessToken = data?.accessToken || null;
            // @ts-ignore
            this.projectId = data?.projectId;
            // @ts-ignore
            if (data.projects && data.projectId && data.projects[data.projectId]) {
                this.currentProject = data.projects[data.projectId];
            }
            // @ts-ignore
            this.projects = data.projects;
            // @ts-ignore
            this.roles = data?.roles || [];

            const userStateCookie = this.cookie;

            // @ts-ignore
            userStateCookie.value  = JSON.parse(JSON.stringify(this.$state));
        },
        async refreshUser() {
            if (!this.isAuthenticated) {
                return;
            }

            const { $api } = useNuxtApp();
            const res = await $api.get('/v1/user/refresh');

            if(res) {
                this.setUser(res);
            }
        },
        async init() {
            try {
                const userStateCookie = this.cookie;
                const userState = userStateCookie.value || {};

                this.setUser(userState);
                await this.refreshUser();
            } catch(e) {
                console.log(e); // eslint-disable-line  no-console
            }
        },
        resetState() {
            this.$state = {
                id : null,
                email: '',
                roles: [],
                projects: [],
                lastname: '',
                firstname: '',
                accessToken: null,
                currentProject: {
                    id: null,
                    title: ''
                },
            };
        },
    },
});
