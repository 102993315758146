import { default as _91slug_93Blx2HKLHc7Meta } from "/builds/menu9411230/nuxt3.menu/pages/[project]/[slug].vue?macro=true";
import { default as _91id_93fgFxR3gyRdMeta } from "/builds/menu9411230/nuxt3.menu/pages/constructor/[id].vue?macro=true";
import { default as _91id_93VUFURtt4CAMeta } from "/builds/menu9411230/nuxt3.menu/pages/editor/[id].vue?macro=true";
import { default as index3srXsQMzl4Meta } from "/builds/menu9411230/nuxt3.menu/pages/index.vue?macro=true";
import { default as login9xVENqM714Meta } from "/builds/menu9411230/nuxt3.menu/pages/login.vue?macro=true";
import { default as accountkh0reJdkB2Meta } from "/builds/menu9411230/nuxt3.menu/pages/manager/account.vue?macro=true";
import { default as indexnKkwymirnNMeta } from "/builds/menu9411230/nuxt3.menu/pages/manager/index.vue?macro=true";
import { default as projectsKzZTAxy3DIMeta } from "/builds/menu9411230/nuxt3.menu/pages/manager/projects.vue?macro=true";
import { default as privacy_45policyRJf8Zi5ucNMeta } from "/builds/menu9411230/nuxt3.menu/pages/privacy-policy.vue?macro=true";
export default [
  {
    name: "project-slug",
    path: "/:project()/:slug()",
    meta: _91slug_93Blx2HKLHc7Meta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/[project]/[slug].vue")
  },
  {
    name: "constructor-id",
    path: "/constructor/:id()",
    meta: _91id_93fgFxR3gyRdMeta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/constructor/[id].vue")
  },
  {
    name: "editor-id",
    path: "/editor/:id()",
    meta: _91id_93VUFURtt4CAMeta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/editor/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login9xVENqM714Meta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/login.vue")
  },
  {
    name: "manager-account",
    path: "/manager/account",
    meta: accountkh0reJdkB2Meta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/manager/account.vue")
  },
  {
    name: "manager",
    path: "/manager",
    meta: indexnKkwymirnNMeta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/manager/index.vue")
  },
  {
    name: "manager-projects",
    path: "/manager/projects",
    meta: projectsKzZTAxy3DIMeta || {},
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/manager/projects.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/builds/menu9411230/nuxt3.menu/pages/privacy-policy.vue")
  }
]