import revive_payload_client_4sVQNw7RlN from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/menu9411230/nuxt3.menu/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/menu9411230/nuxt3.menu/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZGw8LlWmeq from "/builds/menu9411230/nuxt3.menu/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_X7whXyrIWR from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/builds/menu9411230/nuxt3.menu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/builds/menu9411230/nuxt3.menu/plugins/api.ts";
import bus_RrsLoRnN1Z from "/builds/menu9411230/nuxt3.menu/plugins/bus.ts";
import easy_data_table_PL54rUoJny from "/builds/menu9411230/nuxt3.menu/plugins/easy-data-table.ts";
import init_user_ppiNofP5js from "/builds/menu9411230/nuxt3.menu/plugins/init-user.js";
import vue_final_modal_pML93k5qcp from "/builds/menu9411230/nuxt3.menu/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ZGw8LlWmeq,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  api_GFfDXud5Cr,
  bus_RrsLoRnN1Z,
  easy_data_table_PL54rUoJny,
  init_user_ppiNofP5js,
  vue_final_modal_pML93k5qcp
]