import {User} from "~/types/User";
import {Method} from "~/types/Method";
import {useUserStore} from "~/store/user";
import {useClipboard} from "@vueuse/core";

export class API {
    method: Method = 'GET';
    baseURL: string = '';
    fetchUrl: string = '';

    constructor() {
        const runtimeConfig = useRuntimeConfig();
        if (!runtimeConfig?.public?.baseURL) {
            throw new Error('Base url missing');
        }

        // this.baseURL = runtimeConfig.public.baseURL;
        this.baseURL = process.server ? runtimeConfig.private.dockerBaseURL : runtimeConfig.public.baseURL;
    }

    /**
     * GET запрос с обработкой ответа и ошибки...
     * @param fetchUrl
     * @param params
     */
    public get = async (fetchUrl: string, params: Object) => {
        return await this.request('GET', fetchUrl, params)
    };

    /**
     * POST запрос с обработкой ответа и ошибки...
     * @param fetchUrl
     * @param data
     */
    public post = async (fetchUrl: string, data: Object) => {
        return await this.request('POST', fetchUrl, data);
    };

    /**
     * PUT запрос с обработкой ответа и ошибки...
     * @param fetchUrl
     * @param data
     */
    public put = async (fetchUrl: string, data: Object) => {
        return await this.request('PUT', fetchUrl, data);
    };

    /**
     * PUT запрос с обработкой ответа и ошибки...
     * @param fetchUrl
     * @param data
     */
    public patch = async (fetchUrl: string, data: Object) => {
        return await this.request('PATCH', fetchUrl, data);
    };

    /**
     * DELETE запрос с обработкой ответа и ошибки...
     * @param fetchUrl
     * @param data
     */
    public delete = async (fetchUrl: string, data: Object) => {
        return await this.request('DELETE', fetchUrl, data);
    };

    /**
     * Загрузка изображения на сервер...
     * @param fetchUrl
     * @param image
     */
    public uploadImage = async (fetchUrl: string, image: File) => {
        const formData = new FormData();
        formData.append('file', image);

        return await this.request('POST', fetchUrl, formData);
    };

    /**
     * Добавляем Headers для авторизацииююю
     * @param user
     */
    getHeaders = (user: User) => {
        const header = {};

        if (user.isAuthenticated) {
            // @ts-ignore
            header.Authorization = `Bearer ${user.accessToken}`;
        }

        return header;
    }

    /**
     * Базовый метод отправки запроса к API...
     * @param method
     * @param fetchUrl
     * @param params
     */
    private request = async (method: Method, fetchUrl: string, data: Object) => {
        this.method = method;
        this.fetchUrl = fetchUrl;

        const user = useUserStore();
        const headers = this.getHeaders(user);

        const params = {
            method,
            headers,
        };

        if (method === 'GET' && data) {
            // @ts-ignore
            params.params = data
        } else if (data) {
            // @ts-ignore
            params.body = data;
        }

        try {
            // @ts-ignore
            return await $fetch(`${this.baseURL}${fetchUrl}`, params);
        } catch (error: any) {
            console.log('ERROR: ', error);

            if (error.response) {
                const errorMessage = error.response?._data?.message || error.response.statusText;

                const {notify} = useNotification();

                notify({
                    type: 'error',
                    title: 'Error',
                    text: errorMessage,
                });

                if (error.response.status === 401) {
                    const user = useUserStore();
                    user.logout('/login');
                }

                if (error.response.status === 500) {
                    console.error('Server 500 error', error)
                }
            }
        }
    }
};