<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>

    <NuxtNotifications position="bottom right" :speed="500" />
</template>


<script setup lang="ts">
const colorMode = useColorMode();
const toggleTheme = (theme: 'dark' | 'light' | null = null) => {
    colorMode.preference = theme
        ? theme
        : colorMode.preference === 'dark' ? 'light' : 'dark';
};

onMounted(() => {
    toggleTheme('dark');
});
</script>
