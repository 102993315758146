import { defineStore } from 'pinia';
import { useCookie, useRouter } from 'nuxt/app';

export const useManagerStore = defineStore('ManagerStore', {
	state: () => ({
		page: 1,
		count: 0,
		menus: []
	}),
	getters: {},
	actions: {
		async getMenus(page: number | null = null) {
			if(page) {
				this.page = page;
			}

			const { $api } = useNuxtApp();
			const res = await $api.get('/v1/menu', { page: this.page });
			
			if(res) {
				this.count = res.count;
				this.menus = this.page === 1 ? res.data : [...this.menus, ...res.data];
				
				this.page++;
			}
		},
		async createMenu(data: Object) {
			const { $api } = useNuxtApp();
			const res: Object = await $api.post('/v1/menu/create', data);

			if(res) {
				// @ts-ignore
				this.menus = [res, ...this.menus];
				
				return true;
			}

			return false;
		},
		reset() {
			this.page = 1;
			this.count = 0;
			this.menus = [];
		},
	}
});
